.withdraw-routes-dialog {
  .modal-body {
    padding-top: 0;

    .route-table {
      margin-top: 20px;
      font-size: 90%;

      svg {
        height: 18px;
      }
    }
  }
}
